<template>
  <div
    :id="elId"
    v-editable="blok"
    class="video-player"
    :class="{ 'show-controls': display.smAndDown.value && muted }"
  >
    <!-- <video-background
      :src="blok.video.filename"
      style="max-height: 400px; height: 100vh"
    >
      <h1 style="color: white">Hello welcome!</h1>
    </video-background> -->
    <video
      v-if="clientReady"
      ref="video"
      class="rounded rounded-lg"
      :class="[
        breakpoint.lgAndUp ? '_video-background' : 'bottom',
        blok.layout === 'right' ? 'left' : 'right',
      ]"
      :muted
      loop
      :webkit-playsinline="muted"
      :playsinline="muted"
    >
      <source :src="`${blok.video.filename}#t=0.1`" type="video/mp4" />
    </video>

    <div v-if="blok.controls" class="video-buttons">
      <div class="video-play-box">
        <div class="video-play-content-wrap" @click="toggleVideo">
          <div class="video-person-image-wrap">
            <div class="home-video-play-icon">
              <v-icon :icon="muted ? 'mdi-play' : 'mdi-volume-off'" size="40" />
            </div>
          </div>
          <div>
            <div>{{ watchVideoLabel }}</div>
            <div class="gradient-1-color">
              {{ muted ? duration : leftDuration }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useDisplay } from "vuetify";
const display = useDisplay();

const { breakpoint } = useUtils();
const props = defineProps({ blok: Object });

const clientReady = ref(false);
const elId = `video-${props.blok.video.id}`;
const width = computed(() => props.blok.width);
const padding = computed(() => props.blok.padding);
const marginRight = computed(() => props.blok.margin_right);
const scale = computed(() => props.blok.scale || 1);
const video = ref(null);
const played = ref(false);
const muted = ref(true);

function getVideoDuration() {
  if (video.value) {
    return video.value.duration;
  }
}

const duration = computed(() => {
  if (video.value?.duration) {
    return formatTime(video.value.duration);
  } else {
    return "01:49";
  }
});

const leftDuration = ref("");

function toggleVideo() {
  video.value.play();

  if (muted.value) {
    video.value.currentTime = 0;

    leftDuration.value = formatTime(video.value.duration);
    setInterval(() => {
      if (video.value) {
        leftDuration.value = formatTime(
          video.value.duration - video.value.currentTime,
        );
      }
    }, 1000);
  }
  muted.value = !muted.value;
}

function formatTime(seconds) {
  // Rundet die Anzahl der Sekunden auf die nächste ganze Zahl
  seconds = Math.round(seconds);

  // Berechnet die Anzahl der Minuten und Sekunden
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  // Formatiert die Minuten und Sekunden mit führenden Nullen falls nötig
  const formattedMinutes = String(minutes).padStart(2, "0");
  const formattedSeconds = String(remainingSeconds).padStart(2, "0");

  // Gibt das formatierte Zeit zurück (MM:SS)
  return `${formattedMinutes}:${formattedSeconds}`;
}

const watchVideoLabel = computed(() => {
  return muted.value ? "Video ansehen" : "Mute Video";
});

watchEffect(() => {
  if (video.value) {
    video.value.addEventListener("loadedmetadata", () => {
      getVideoDuration();

      if (display.mdAndUp.value || props.blok.controls) {
        setTimeout(() => {
          video.value.play();
        }, 500);
      }
    });
  }
});

onMounted(() => {
  clientReady.value = true;
});
//   setTimeout(() => {
//     // init controller
//     const controller = new ScrollMagic.Controller({ container: "body" });

//     // create a scene
//     const scene = new ScrollMagic.Scene({
//       triggerElement: `#${elId}`,
//       offset: -50, // start this scene after scrolling for 50px
//     })
//       .on("start", function (ev) {
//         if (typeof ev.progress !== "undefined" && !played.value) {
//           played.value = true;
//           try {
//             video.value.play();
//           } catch (e) {}
//         }
//       })
//       .addTo(controller); // assign the scene to the controller
//   }, 500);
// });
</script>

<style scoped lang="scss">
.video-background {
  position: absolute;
  top: 0;
  z-index: 0;
  height: calc(110% + 150px);
  max-width: v-bind(width);
  margin-top: calc((150px / 2) * -1);
  margin-right: v-bind(marginRight);
  zoom: v-bind(scale);
}
.video-background.right {
  right: 0;
}
.video-background.left {
  left: 0;
}
.bottom {
  margin-bottom: calc(-1 * v-bind(padding));
}
.video-buttons {
  position: absolute;
  top: 50%; /* Positioniert das Element vertikal in die Mitte */
  left: 50%;
  transform: translate(
    -50%,
    -50%
  ); /* Zentriert das Element horizontal und vertikal */
  z-index: 1;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.video-player {
  .video-buttons {
    opacity: 0;
    transition: all 0.4s;
  }

  &:hover,
  &.show-controls {
    .video-buttons {
      opacity: 1;
    }
  }
}

.video-play-content-wrap {
  .home-video-play-icon {
    transition: all 0.4s;
  }

  &:hover {
    .home-video-play-icon {
      transform: scale(1.3);
    }
  }
}
</style>
